<template>
  <div class="app__toolbar-wrapper" role="banner">
    <div class="app__toolbar above">
      <TheSoundButton />
      <BylineButton v-if="byline" :text="byline" />
      <LanguageToggleButton v-if="!inGame" />
    </div>
    <div v-if="!inGame" class="app__toolbar below">
      <LanguagePicker />

      <BaseButton
        v-if="privacyPolicyUrl"
        :href="privacyPolicyUrl"
        new-tab
        is-quaternary
        >{{ $l10n('privacy-policy') }}</BaseButton
      >

      <BaseButton
        v-if="termsOfUseUrl"
        :href="termsOfUseUrl"
        new-tab
        is-quaternary
        >{{ $l10n('terms-of-use') }}</BaseButton
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    inGame: Boolean,
    byline: String,
    privacyPolicyUrl: String,
    termsOfUseUrl: String,
  },
};
</script>
