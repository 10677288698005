import Vue from 'vue';
import forOwn from 'lodash/forOwn';
import { mapMutations } from 'vuex';
import VueMeta from 'vue-meta';
import PortalVue from 'portal-vue';
import { Howler } from 'howler';

import {
  name as surveyName,
  config,
  share_config,
  translations,
  main_questions,
  followup_interfaces,
  recovery_questions,
  interrupt_questions,
  demographic_survey,
  levels,
  languages,
  surveyLanguage,
} from './config.js';

import store from './utilities/store.js';
import fixText from './utilities/text.js';
import l10n from './utilities/l10n.js';
import baseUrl from './utilities/url.js';
import logging from './utilities/logging.js';
import sounds from './utilities/sounds.js';
import VueGtag from 'vue-gtag';
import VueRouter from 'vue-router';
import App from '../framework/components/App.vue';

const BASE_URL = process.env.VUE_APP_BASE_URL || process.env.BASE_URL;

Vue.config.productionTip = false;
Vue.config.devtools = true;

Vue.use(VueRouter);
Vue.use(VueMeta);
Vue.use(PortalVue);
Vue.use(fixText);
Vue.use(l10n, translations);
Vue.use(baseUrl, BASE_URL);
Vue.use(logging, surveyName, store);

if (process.env.VUE_APP_GOOGLE_ANALYTICS_UA && !navigator.webdriver) {
  Vue.use(VueGtag, {
    config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_UA, anonymize_ip: true },
  });
}

Vue.mixin({
  methods: mapMutations(['showModal', 'hideModal']),
});

store.state.baseURL = BASE_URL;
store.state.languages = languages;

Howler.volume(store.state.useAudio ? 1 : 0);

// Handle auto-muting when focus leaves/returns
var hiddenProp, visibilityChange;
if (typeof document.hidden !== 'undefined') {
  // Opera 12.10 and Firefox 18 and later support
  hiddenProp = 'hidden';
  visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
  hiddenProp = 'msHidden';
  visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
  hiddenProp = 'webkitHidden';
  visibilityChange = 'webkitvisibilitychange';
}

document.addEventListener(visibilityChange, function () {
  if (document[hiddenProp]) {
    Howler.volume(0);
  } else {
    if (store.state.useAudio) {
      Howler.volume(1);
    }
  }
});

export default function init({ soundEffects, ...appConfig }, components) {
  // Globally register all provided components
  forOwn(components, (component, name) => {
    Vue.component(name, component);
  });

  if (soundEffects) {
    Vue.use(sounds, soundEffects.source, soundEffects.sprite);
  }

  const props = {
    ...config,
    main_questions,
    followup_interfaces,
    recovery_questions,
    interrupt_questions,
    demographic_survey,
    levels: levels?.map((level, i) => ({ index: i, ...level })),
    languages,
    share_config,
    surveyLanguage,
    ...appConfig,
  };

  //const catchAll = { path: '/*', component: App };
  // routes that have different loadouts of survey configuration
  const alternateDataSets = {
    path: '/survey-*-*',
    component: App,
  };
  const primaryLevels = { path: '/l*', component: App };
  const subLevels = { path: '/survey-*-*-l*', component: App };
  // if we are not running on a headless browser, redirect user
  if (!navigator.webdriver) {
    //catchAll.redirect = '/';
    //primaryLevels.redirect = '/';
    //subLevels.redirect = '/';
    // alternateDataSets.redirect = to => {
    //   const { fullPath } = to;
    //   console.log(to, fullPath);
    //   return fullPath + '/index.html';
    // };
  }

  const routes = [
    { path: '/', component: App },
    alternateDataSets,
    primaryLevels,
    subLevels,
    //catchAll,
  ];

  const router = new VueRouter({
    routes,
    mode: 'history',
  });

  new Vue({
    store,
    el: '#app',
    router,
    mounted() {
      // You'll need this for renderAfterDocumentEvent.
      document.dispatchEvent(new Event('render-event'));
    },
    render: h => h(App, { props }),
  });
}
