<template>
  <StyledLanguageButton>
    <BaseButton
      id="language-toggle"
      name="languageToggle"
      location="main"
      class="byline"
      is-quaternary
      data-cy="languageToggleButton"
      @click="toggleLanguage"
      >{{ this.toggleLanguageCode }}</BaseButton
    >
  </StyledLanguageButton>
</template>

<script>
import StyledBylineButton from '../framework/styled-components/StyledBylineButton';
import styled from 'vue-styled-components';
import globeIcon from '../assets/img/globe.svg';

const StyledLanguageButton = styled(StyledBylineButton)`
  .button__inner:before {
    border: none !important;
    border-radius: none !important;
    content: '' !important;
    background: url(${globeIcon});
    width: 15px !important;
    height: 15px !important;
  }
  .button__inner:hover:before {
    filter: invert();
  }
  .button.is-quaternary:focus {
    .button__inner:before {
      filter: invert();
    }
  }
`;
export default {
  components: {
    StyledLanguageButton,
  },
  data() {
    return {
      toggleLanguageCode:
        window.SURVEY_CONFIG.surveyLanguage === 'en' ? 'FR' : 'EN',
    };
  },
  methods: {
    toggleLanguage: () => {
      // TODO: csps specific
      let alternativeLanguageSlug = process.env.VUE_APP_BASE_URL;
      // we want to send user to the alternative language
      // in this particular case, csps wants to route us to french
      if (window.SURVEY_CONFIG.surveyLanguage === 'en') {
        alternativeLanguageSlug += 'fr.html';
      }
      window.location.href = alternativeLanguageSlug;
    },
  },
};
</script>
