<template>
  <BaseScreen
    name="end"
    :class="['endscreen', 'is-level-' + (level.index + 1)]"
  >
    <div class="endscreen__header">
      <StarGraphic :number="level.index + 1" class="endscreen__star" />

      <!-- csps only -->
      <h2 role="alert">
        {{ $l10n('finish-rank', $l10n(this.level.title)) }}
      </h2>
      <p v-if="level.message">{{ level.message }}</p>

      <p v-if="showUUID && session">
        {{ $l10n('your-uuid') }}
        <code class="endscreen__code">{{ session }}</code>
      </p>
      <p>{{ $l10n('finish-message') }}</p>

      <!-- CSPS Specific change, to remove these last buttons -->
      <!-- <BaseButton
        name="share"
        location="end"
        is-primary
        @click="showModal('share')"
        >{{ $l10n('share-level') }}</BaseButton
      >
      -->
      <BaseButton
        name="restart"
        location="end"
        is-primary
        @click="$emit('restart')"
        >{{ $l10n('restart-no-or') }}</BaseButton
      >
      <div v-html="$l10n('keep-learning')" />
      <div v-html="$l10n('source-list')" />
    </div>

    <div
      class="endscreen__graphic"
      :style="{
        'background-image': `url(${$url(level.graphic)})`,
      }"
    />
  </BaseScreen>
</template>

<script>
export default {
  inject: ['showUUID'],
  props: {
    level: {
      type: Object,
      required: true,
    },
    text: String,
  },
  computed: {
    session() {
      const session = this.$store.state.session;

      if (this.showUUID === true) {
        return session;
      } else if (this.showUUID) {
        return session.substr(-1 * this.showUUID);
      }

      return false;
    },
  },
};
</script>
