<template>
  <transition>
    <div
      v-show="isOpen"
      :id="id"
      role="main"
      :class="{
        screen: true,
        'is-open': isOpen,
      }"
      tabindex="-1"
    >
      <slot />
    </div>
  </transition>
</template>

<script>
import { mapState } from 'vuex';

export default {
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      required: true,
    },
    id: String,
  },
  computed: mapState({
    isOpen(state) {
      return state.currentScreen === this.name;
    },
  }),
  watch: {
    isOpen(isOpen) {
      if (isOpen) {
        this.$emit('show');
        this.$nextTick(() => this.$el.focus());
      } else {
        this.$el.blur();
        this.$emit('hide');
      }

      this.$emit('toggle', isOpen);
    },
  },
  provide() {
    return {
      parentView: this.name,
    };
  },
};
</script>
