<template>
  <BaseModal
    name="share"
    :class="['sharescreen', 'is-level-' + (level.index + 1)]"
  >
    <h2>{{ $l10n('share-level') }}</h2>

    <div class="sharescreen__poster">
      <StarGraphic :number="level.index + 1" class="sharescreen__star" />
      <div
        class="sharescreen__graphic"
        :style="{
          'background-image': `url(${$url(level.graphic)})`,
        }"
      />
    </div>

    <div class="sharescreen__info">
      <p v-content="text" />

      <ShareButtonGroup :link="level.id" />
    </div>
  </BaseModal>
</template>

<script>
export default {
  props: {
    level: {
      type: Object,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
};
</script>
