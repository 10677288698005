import styled from 'vue-styled-components';
import get from 'better-theme-get';

export default styled.div`
  ${get('ml', 1)};
  ${get('color', 'text')};
  .button.is-quaternary {
    background: linear-gradient(
        to right,
        ${get('colors.text')} 50%,
        ${get('colors.buttonBackground')} 50%
      )
      no-repeat;
    background-size: 201% 100%;
    background-position: right center;
    &:hover,
    &:focus {
      color: ${get('colors.buttonText')} !important;
      background-position: left center;
    }
  }
`;
