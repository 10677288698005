<template>
  <BaseScreen id="homescreen" name="home" class="homescreen">
    <div class="homescreen__hand homescreen__hand--thumbup"></div>
    <div class="homescreen__hand homescreen__hand--thumbdown"></div>
    <div class="homescreen__header">
      <StyledCloud
        class="homescreen__header__graphic cloud"
        view-box="0 0 256 208"
      >
        <path
          class="cloud__shape"
          d="M48.9,59.2c4.8-32.3,34.8-54.6,67-49.8c12.7,1.9,24.4,7.8,33.4,16.9c40.6-11,82.4,13.1,93.4,53.7c11,40.6-13.1,82.4-53.7,93.4c-4.2,1.1-8.4,1.9-12.7,2.3c-19.3,25.9-56,31.2-81.8,11.9c-2.3-1.7-4.4-3.6-6.5-5.6c-35.2,6.8-69.2-16.1-76.1-51.3C6.1,101.2,21.5,71.6,48.9,59.2z"
        />
        <path
          class="cloud__outline"
          d="M44.1,56.5c5-34.2,36.9-57.9,71.1-52.8c13.4,2,25.9,8.3,35.4,17.9c43.1-11.6,87.4,13.9,99,57c11.6,43.1-13.9,87.4-57,99c-4.4,1.2-8.8,2-13.4,2.4c-20.5,27.5-59.3,33.1-86.8,12.6c-2.4-1.8-4.7-3.8-6.8-5.9c-37.3,7.3-73.4-17.1-80.7-54.4C-1.2,101,15,69.7,44.1,56.5z"
        />
      </StyledCloud>
      <h1 v-content="title" class="homescreen__title" />
      <p v-if="tagline" v-content="tagline" class="homescreen__tagline" />
    </div>
    <div class="homescreen__body">
      <BaseButton
        ref="startButton"
        name="start"
        location="home"
        is-primary
        is-large
        data-cy="start"
        @click="$emit('next')"
        >{{ buttonText || $l10n('start') }}</BaseButton
      >
    </div>
    <StyledBackgroundAnim
      v-for="n in 6"
      :key="n"
      :class="['homescreen__decor', n % 2 ? 'top' : 'bottom']"
      :style="{
        [n % 2 ? 'top' : 'bottom']: '25%',
        'animation-delay': n * -60 + 's',
        'animation-duration': Math.round(Math.random() * 60) + 40 + 's',
        'animation-direction': Math.random() % 2 ? 'normal' : 'reverse',
      }"
    >
      <img
        src="../assets/img/virus.svg"
        class="homescreen__decor__image"
        alt="background virus sprite"
        :width="Math.round((Math.random() * 0.5 + 0.5) * 224)"
        :style="{
          'animation-delay': n * -30 + 's',
          'animation-duration': Math.round(Math.random() * 30) + 20 + 's',
          'animation-direction': Math.random() % 2 ? 'reverse' : 'normal',
        }"
      />
    </StyledBackgroundAnim>
  </BaseScreen>
</template>

<script>
import StyledCloud from '../styled-components/StyledCloud';
import StyledBackgroundAnim from '../styled-components/StyledBackgroundAnim';
export default {
  components: {
    StyledBackgroundAnim,
    StyledCloud,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    tagline: String,
    buttonText: String,
  },
  mounted() {
    setTimeout(() => {
      this.$refs.startButton.$el.focus();
    }, 1000);
  },
};
</script>
