<template>
  <StyledSoundButton>
    <BaseButton
      :name="useAudio ? 'mute' : 'unmute'"
      location="main"
      :class="{
        soundtoggle: true,
        'is-muted': !useAudio,
      }"
      is-tertiary
      data-cy="soundButton"
      @click="toggleAudio"
    >
      <BaseIcon v-show="useAudio" type="soundOn" class="soundtoggle__icon" />
      <BaseIcon v-show="!useAudio" type="soundOff" class="soundtoggle__icon" />

      <BaseIcon type="waves" class="soundtoggle__waves" />

      <ScreenReaderText v-if="useAudio">{{ $l10n('mute') }}</ScreenReaderText>
      <ScreenReaderText v-else>{{ $l10n('unmute') }}</ScreenReaderText>
    </BaseButton>
  </StyledSoundButton>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import StyledSoundButton from '../styled-components/StyledSoundButton';

export default {
  components: {
    StyledSoundButton,
  },
  computed: {
    ...mapState(['useAudio']),
  },
  methods: mapMutations(['toggleAudio']),
};
</script>
