import styled from 'vue-styled-components';
import get from 'better-theme-get';

export default styled.div`
  svg {
    ${get('color', 'text')};
  }
  button {
    background: ${get('colors.buttonBackground')} !important;
    border-color: ${get('colors.buttonBorder')} !important;
  }
`;
