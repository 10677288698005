<template>
  <StyledBylineButton>
    <BaseButton
      id="byline"
      name="byline"
      location="main"
      class="byline"
      is-quaternary
      data-cy="bylineButton"
      @click="showModal('about')"
      >{{ text }}</BaseButton
    >
  </StyledBylineButton>
</template>

<script>
import StyledBylineButton from '../styled-components/StyledBylineButton';
export default {
  components: {
    StyledBylineButton,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
  },
};
</script>
