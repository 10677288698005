export default matchedPath => {
  let surveyLevel;
  let parsedPath = matchedPath.replace(/^\/|\/$/g, '');
  if (matchedPath && matchedPath.length > 1 && matchedPath.includes('-')) {
    // detect if we have any trailing "params" like survey-1-en-l2
    // we want to remove them and simply return the first 3 parts of the path
    if (parsedPath.split('-').length > 1) {
      parsedPath = parsedPath.split('-');
      surveyLevel = parsedPath[parsedPath.length - 1];
      parsedPath = parsedPath.slice(0, 3);
    }
  } else {
    surveyLevel = parsedPath;
  }
  return surveyLevel.surveyScriptName.replace('.html', '');
};
